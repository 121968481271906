import { stripTags } from '@zicht/nuxt-util'
import { getMediaSeoImage } from '~/assets/media'

function getHomeUrl(page) {
  const match = page?.url?.match(/^(https?):\/\/([^/]+)/i)
  return match ? `${match[1]}://${match[2]}/` : undefined
}

function getCanonicalAndAlternates(page) {
  const canonical = { href: page?.seo?.advanced.canonical || page?.url }
  let alternates = [
    {
      href: page?.alternativeLanguages?.filter(({ language }) => language === 'en').map(({ url }) => url)?.[0],
      hreflang: 'x-default',
    },
    ...page?.alternativeLanguages?.map(({ url, language }) => ({
      href: url === page?.url ? canonical.href : url,
      hreflang: language,
    })),
  ]

  let allowAlternateLang
  switch (page?.section) {
    case 'publicationPage':
      if ((page.language === 'nl' && page.publicationLanguage === 'Dutch') || (page.language === 'en' && page.publicationLanguage === 'English')) {
        // Only alternates in own language - [DELTA-43]
        allowAlternateLang = page.language
      }
      if ((page.language === 'en' && page.publicationLanguage === 'Dutch') || (page.language === 'nl' && page.publicationLanguage === 'English')) {
        // Set other lang alternate as canonical when the publication/content is in the other lang - [DELTA-8]
        canonical.href = page.alternativeLanguages?.filter(({ language }) => language !== page.language).map(({ url }) => url)?.[0]
        canonical.hreflang = page.language === 'nl' ? 'en' : 'nl'
        // Remove self as alternate - [DELTA-43]
        allowAlternateLang = page.language === 'nl' ? 'en' : 'nl'
      }
      alternates = [
        { href: canonical.href, hreflang: 'x-default' },
        ...page.alternativeLanguages
          ?.filter(({ language }) => language === allowAlternateLang)
          .map(({ url, language }) => ({ href: url === page.url ? canonical.href : url, hreflang: language })),
      ]
      break
    case 'homePage':
      // Strip of the trailing slash from the canonical and alternates for the English homepage
      // The URL path becomes "/en/" because of the "en" prefix set by i18n, while the actual
      // URL path must be "/en" - [DELTA-26]
      canonical.href = stripTrailingSlashFromEnHomeUrl(canonical.href)
      alternates.forEach((alternate) => {
        alternate.href = stripTrailingSlashFromEnHomeUrl(alternate.href)
      })
      break
  }

  return { canonical, alternates }
}

const stripTrailingSlashFromEnHomeUrl = (url) => {
  if (typeof url !== 'string' || !url.includes('/en/')) {
    return url
  }
  const urlParts = new URL(url)
  if (urlParts.pathname !== '/en/') {
    return url
  }
  urlParts.pathname = '/en'
  return urlParts.href
}

export const seoHead = (page) => {
  const pageImage = getMediaSeoImage(page?.image || undefined)
  const pageDescription = stripTags(page?.introduction || page?.description || page?.dynamicContent?.filter(({ type }) => type === 'text').map(({ text }) => text)?.[0] || '')

  const meta = [
    { name: 'description', content: page?.seo?.description || pageDescription },
    { name: 'keywords', content: page?.seo?.keywords },

    // Robots
    // https://developers.google.com/search/docs/advanced/robots/robots_meta_tag
    { name: 'robots', content: page?.seo?.advanced.robots },
    { name: 'robots', content: page?.expiryDate ? `unavailable_after: ${page.expiryDate}` : undefined },

    // Twitter
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: page?.seo?.seoGlobal?.twitterHandle },
    { name: 'twitter:url', content: page?.url },
    { name: 'twitter:title', content: page?.seo?.social.twitter.title },
    { name: 'twitter:description', content: page?.seo?.social.twitter.description || pageDescription },
    { name: 'twitter:image', content: page?.seo?.social.twitter.image?.url || pageImage?.url },

    // Facebook
    { property: 'fb:app_id', content: page?.seo?.seoGlobal?.facebookHandle },
    { property: 'og:url', content: page?.url },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: page?.seo?.social.facebook.title },
    { property: 'og:image', content: page?.seo?.social.facebook.image?.url || pageImage?.url },
    { property: 'og:image:width', content: page?.seo?.social.facebook.image?.width || pageImage?.width },
    { property: 'og:image:height', content: page?.seo?.social.facebook.image?.height || pageImage?.height },
    { property: 'og:description', content: page?.seo?.social.facebook.description || pageDescription },
    { property: 'og:site_name', content: page?.seo?.seoGlobal?.siteName },
    { property: 'og:locale', content: page?.language },
  ]

  const { canonical, alternates } = getCanonicalAndAlternates(page)
  const link = [
    { rel: 'home', href: getHomeUrl(page) },
    { rel: 'canonical', href: canonical.href, ...(canonical.hreflang !== undefined && { hreflang: canonical.hreflang }) },
    ...alternates.map((alternate) => ({ rel: 'alternate', href: alternate.href, hreflang: alternate.hreflang })),
  ]

  // https://github.com/ethercreative/seo/blob/v3/src/templates/_seo/meta.twig
  return {
    htmlAttrs: { lang: page?.language || 'nl' },
    link: link.filter(({ href }) => href),
    meta: meta.filter(({ content }) => content),
    title: page?.seo?.title,
  }
}
