
import { defineComponent } from '@nuxtjs/composition-api'
import { seoHead } from '~/assets/seo'

export default defineComponent({
  async asyncData({ store, error }) {
    const [, page] = await Promise.all([store.dispatch('navigation/fetch'), store.dispatch('page/fetchHome', { push: true })])
    if (!page) {
      return error({ statusCode: 500 })
    }
    return { page }
  },
  head() {
    return seoHead(this.page)
  },
})
